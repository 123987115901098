import React from "react";
import SubpageTemplate from "../components/SubpageTemplate";

const DataPrivacy = () => {
  const content = `With over two decades of experience across multiple jurisdictions, I
    specialise in privacy law and data protection. Having acted as an
    external Data Protection Officer (DPO) for international organizations,
    I offer a comprehensive approach to privacy compliance. I have
    successfully carried out privacy audits, formulated GDPR compliance
    strategies, and negotiated complex data processing agreements for
    clients in France, Spain, and the UK. My hands-on experience advising
    major corporations like Verizon and Proofpoint ensures that my clients
    receive top-tier legal guidance on protecting personal data across
    multiple sectors.`;

  return (
    <SubpageTemplate title="Data Protection & Security" content={content} />
  );
};

export default DataPrivacy;
