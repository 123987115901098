import React from "react";
import { Link } from "react-router-dom";
import "./SimplePageTemplate.css"; // Ensure you style accordingly

const SimplePageTemplate = ({ title, children }) => {
  return (
    <div className="simple-page-container">
      <main className="content-area">
        <h1 className="page-title">{title}</h1>
        <div className="content-wrapper">{children}</div>
      </main>

      <footer className="footer">
        <p>© {new Date().getFullYear()} Technomika. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default SimplePageTemplate;
