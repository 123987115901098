import React from "react";
import { useEffect, useState } from "react";
import "../style.css";
import { Link } from "react-router-dom";
import Axios from "axios";

export default function WorkDb() {
  const [works, setWorks] = useState(null);
  const [worklist, setWork] = useState([]);

  useEffect(() => {
    Axios.get("https://bontech2.herokuapp.com/readworks").then((response) => {
      console.log(response);
      setWork(response.data, response.key);
    });
  }, []);

  return (
    <div className="body--text--work--grid">
      <div className="front--blog--card">
        {worklist.map((val, key) => {
          return (
            <div className="work--preview" key={key}>
              <Link to={`/matters/${val.id_works}`}>
                <h3>{val.matter_title}</h3>
                <h2>Client: {val.bon_client}</h2>
                <h6>Tags: {val.work_tag}</h6>
                <h4>{val.work_text}</h4>
                <h5>{val.post_fulltext}</h5>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
