import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./style.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import netlifyIdentity from "netlify-identity-widget";

ReactDOM.render(<App />, document.getElementById("root"));
