import "./App.css";
import React from "react";
import "./index.css";
import Headerbon from "./components/header";
import "./style.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";
import ContactForm from "./components/ContactForm";
import DataPrivacy from "./pages/DataPrivacy";
import BlogPage from "./components/BlogPage"; // Import the BlogPage
import BlogPostPage from "./components/BlogPostPage"; // Import the BlogPostPage
import CookieConsent from "react-cookie-consent";
import CaseStudiesPage from "./pages/CaseStudiesPage";
import CaseStudyDetailPage from "./pages/CaseStudyDetailPage";
import Cybersecurity from "./pages/Cybersecurity";
import AiGovernance from "./pages/AiGovernance";
import TechContracts from "./pages/TechContracts";
import IpConf from "./pages/IpConf";
import About from "./pages/about";
import AdminPage from "./components/AdminPage";
import CreatePost from "./components/CreatePost";
import EditPost from "./components/EditPost";

function App() {
  return (
    <Router>
      <Headerbon />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services/data-privacy" element={<DataPrivacy />} />
        <Route path="/services/cybersecurity" element={<Cybersecurity />} />
        <Route path="/services/ai-governance" element={<AiGovernance />} />
        <Route path="/services/tech-contracts" element={<TechContracts />} />
        <Route path="/services/ip-confidentiality" element={<IpConf />} />
        <Route path="/policy" element={<Privacy />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/blog" element={<BlogPage />} />{" "}
        <Route path="/sitemap.xml" />
        {/* Route for blog list */}
        <Route path="/blog/:id" element={<BlogPostPage />} />{" "}
        {/* Route for individual blog post */}
        <Route path="/casestudies" element={<CaseStudiesPage />} />
        <Route path="/casestudies/:workid" element={<CaseStudyDetailPage />} />
        {/* PROTECTED ROUTE */}
        <Route path="/users/admin" element={<AdminPage />} />
        <Route path="/users/admin/create-post" element={<CreatePost />} />
        <Route path="/users/admin/edit-post/:postId" element={<EditPost />} />
        {/* PROTECTED ROUTE */}
      </Routes>
      {/* <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
    </Router>
  );
}

export default App;
