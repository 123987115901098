import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import react-helmet
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import "./BlogPostPage.css";

const BlogPostPage = () => {
  const { id } = useParams(); // Extracting the `id` from the URL
  const [post, setPost] = useState(null); // State for storing the blog post
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    console.log("Received id:", id); // Add this line to log the ID
    const fetchPost = async () => {
      try {
        const response = await Axios.get(
          `https://bontech2.herokuapp.com/readspecblog/${id}`
        );
        console.log("Response data:", response.data); // Log the API response
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setError("Error fetching blog post.");
        setLoading(false);
      }
    };

    if (id) {
      fetchPost();
    } else {
      console.error("Blog post ID is undefined.");
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>No blog post found.</div>;
  }

  const shareUrl = window.location.href;
  const shareTitle = post.post_title || "Untitled Post";
  const shareDescription = post.post_fulltext || "No description available";
  const imageUrl = "https://technomika.com/nomikalogo.png"; // Update to the actual image URL

  return (
    <div className="blog-post-page">
      {/* Helmet for Social Media Sharing */}
      <Helmet>
        <title>{shareTitle}</title>
        <meta property="og:title" content={shareTitle} />
        <meta property="og:description" content={shareDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={shareTitle} />
        <meta name="twitter:description" content={shareDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <div className="social-share">
        <h3>Share this post:</h3>
        <FacebookShareButton url={shareUrl} quote={shareTitle}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          title={shareTitle}
          summary={post.excerpt || ""}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
      <h1>{post.post_title || "Untitled Post"}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: post.post_fulltext || "<p>No content available</p>",
        }}
      ></div>

      <div className="social-share">
        <h3>Share this post:</h3>
        <FacebookShareButton url={shareUrl} quote={shareTitle}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          title={shareTitle}
          summary={post.excerpt || ""}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default BlogPostPage;
