import React from "react";
import SubpageTemplate from "../components/SubpageTemplate";

const AiGovernance = () => {
  const content = `  AI presents novel legal challenges, particularly in governance, ethics,
        and compliance. I assist organizations in navigating these complexities
        by providing legal counsel on AI governance frameworks. Drawing from my
        international experience, I work closely with companies to ensure that
        their AI deployments meet ethical and regulatory standards. My advisory
        covers contractual governance, data use in AI, and implementing policies
        that comply with EU and international AI regulations, ensuring
        responsible AI development and use.
    `;

  return <SubpageTemplate title="AI Governance" content={content} />;
};

export default AiGovernance;
