import React from "react";
import { Link } from "react-router-dom";
import "./SubpageTemplate.css"; // You can create a CSS file for subpages
import { Helmet } from "react-helmet";

const SubpageTemplate = ({ title, content }) => {
  return (
    <>
      <Helmet>
        <title>{`${title} - TECHNOMIKA`}</title>
        <meta name="description" content={`${title} page - TECHNOMIKA`} />
      </Helmet>
      <header className="subpage-header">
        <div className="subpage-hero">
          <div className="subpage-hero-content">
            <h1>{title}</h1>
            <p>Delivering high-quality legal services in {title}</p>
            <Link to="/contact" className="cta-button">
              Get in Touch
            </Link>
          </div>
        </div>
      </header>

      <main>
        <section className="subpage-content">
          <div className="content-wrapper">
            <div className="main-content">
              <article>
                <h2>{title}</h2>
                <p>{content}</p>
              </article>
            </div>
          </div>
        </section>
      </main>

      <footer className="subpage-footer">
        <p>© 2024 TECHNOMIKA. All rights reserved.</p>
      </footer>
    </>
  );
};

export default SubpageTemplate;
