import React from "react";
import SubpageTemplate from "../components/SubpageTemplate";

const Cybersecurity = () => {
  const content = `
        In today’s rapidly evolving digital landscape, I provide strategic legal
        counsel that not only safeguards companies' digital infrastructure but
        also demonstrates a deep understanding of the technical complexities of
        cybersecurity. My training at the renowned SANS Institute in Security
        Essentials equipped me with key technical insights into information
        security. This technical foundation, combined with my experience
        supporting industry leaders like Proofpoint and Verizon, enables me to
        navigate complex cybersecurity service agreements and address sensitive
        data protection issues for both public and private sector clients. With
        extensive experience working alongside cybersecurity service providers
        and SaaS companies, I offer expert advice on contractual, regulatory,
        and technical security measures aimed at mitigating cyber risks. My
        particular focus on GDPR Article 32 compliance and data breach
        management allows me to guide clients through the intricacies of
        regulatory obligations and the implementation of robust data protection
        practices.`;

  return <SubpageTemplate title="Cybersecurity" content={content} />;
};

export default Cybersecurity;
