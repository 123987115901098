import React from "react";
import SubpageTemplate from "../components/SubpageTemplate";

const IpConf = () => {
  const content = `
        As a former visiting lecturer in Intellectual Property Law at King’s
        College London, I bring deep knowledge of intellectual property
        protection to my clients. My practice covers the full spectrum of IP
        issues, including software licensing, patent protection, and
        confidentiality agreements. I have assisted both multinational
        corporations and startups in securing and managing their intellectual
        property assets, ensuring that their innovations remain protected in the
        competitive global market. These tailored descriptions should align well
        with your professional experience and provide clear insights into the
        services you offer.`;

  return <SubpageTemplate title="IP & Confidentiality" content={content} />;
};

export default IpConf;
