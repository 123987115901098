import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

const CaseStudyDetailPage = () => {
  const { workid } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      try {
        const response = await Axios.get(
          `https://bontech2.herokuapp.com/matters/${workid}`
        );
        if (response.data) {
          setCaseStudy(response.data);
        } else {
          setError("No case study found.");
        }
      } catch (err) {
        setError("Error fetching case study.");
      } finally {
        setLoading(false);
      }
    };

    if (workid) {
      fetchCaseStudy();
    }
  }, [workid]);

  if (loading) {
    return <div>Loading case study...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!caseStudy) {
    return <div>No case study data available.</div>;
  }

  const shareUrl = window.location.href;
  const shareTitle = caseStudy.matter_title || "Title not available";
  const shareDescription = caseStudy.work_text || "No description available";
  const imageUrl = "https://technomika.com/nomikalogo.png"; // Update to your image URL

  return (
    <div className="case-study-detail-page">
      <Helmet>
        <title>{shareTitle}</title>
        <meta property="og:title" content={shareTitle} />
        <meta property="og:description" content={shareDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta
          property="og:url"
          content={`https://technomika.com/casestudies/${workid}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Technomika" />
        <meta property="og:locale" content="en_UK" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={shareTitle} />
        <meta name="twitter:description" content={shareDescription} />
        <meta name="twitter:image" content={imageUrl} />
        <link
          rel="canonical"
          href={`https://technomika.com/casestudies/${workid}`}
        />
      </Helmet>

      <h1>{shareTitle}</h1>
      <p>{shareDescription}</p>

      <div className="social-share">
        <h3>Share this case study:</h3>
        <FacebookShareButton url={shareUrl} quote={shareTitle}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          title={shareTitle}
          source={shareUrl}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default CaseStudyDetailPage;
