import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Headerbon.css"; // External CSS for styling the header

const Headerbon = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="logo-container">
          <img
            src={`${process.env.PUBLIC_URL}/nomikalogo.png`}
            alt="Logo"
            className="logo"
          />
          <h1 className="company-name">TECHNOMIKA</h1>
        </div>
        <nav className="navbar">
          <div
            className={`hamburger ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`nav-links ${menuOpen ? "show" : ""}`}>
            <li>
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>{" "}
              {/* Add the Blog link */}
            </li>
            <li>
              <Link to="/blog" onClick={toggleMenu}>
                Blog
              </Link>{" "}
              {/* Add the Blog link */}
            </li>
            <li>
              <Link to="/casestudies" onClick={toggleMenu}>
                Our Work
              </Link>{" "}
              {/* Add Case Studies link */}
            </li>
            <li>
              <Link to="/policy" onClick={toggleMenu}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleMenu}>
                Contact
              </Link>{" "}
              {/* New Contact link */}
            </li>
            {/* Add more navigation links as needed */}
          </ul>
        </nav>
      </header>

      <div className="header-subtext">
        <p>IP | IT | Data Security | AI </p>
      </div>
    </>
  );
};

export default Headerbon;
