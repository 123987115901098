import React from "react";

const ContactRedirect = () => (
  <div>
    <h2>Contact Us</h2>
    <p>
      Please fill out our form <a href="/contact.html">here</a>.
    </p>
  </div>
);

export default ContactRedirect;
