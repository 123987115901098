import "./App.css";
import React from "react";
import "./index.css";
import "./style.css";
import Handlecookies from "./components/handlecookies";
import ShowPage from "./components/showpage";
import AuthButtons from "./components/AuthButtons";

function Home() {
  return (
    <div>
      <ShowPage />
      <Footerbon />
      <Handlecookies />
    </div>
  );
}

function Footerbon() {
  return (
    <footer className="footer--main">
      <p> </p>
      <h6>©2024 PyPanther LLC.</h6>
      <div className="auth-button-wrapper">
        <AuthButtons />
      </div>
    </footer>
  );
}

export default Home;
