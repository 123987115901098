import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./CaseStudiesPage.css";

const CaseStudiesPage = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const response = await Axios.get(
          "https://bontech2.herokuapp.com/readworks"
        );
        console.log(response.data); // Log the response data to see its structure
        setCaseStudies(response.data);
      } catch (error) {
        setError("Error fetching case studies.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseStudies();
  }, []);

  if (loading) {
    return <div>Loading case studies...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (caseStudies.length === 0) {
    return <div>No case studies available at the moment.</div>;
  }

  return (
    <div className="case-studies-page">
      <h1>Our Work</h1>
      <div className="case-studies-list">
        {caseStudies.map((caseStudy) => (
          <div key={caseStudy.id_works} className="case-study-card">
            <h2>{caseStudy.matter_title}</h2>
            <p>{caseStudy.work_snipet}</p>
            <Link to={`/casestudies/${caseStudy.id_works}`}>Read More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudiesPage;
