import React, { useState } from "react";
import Axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import "@tinymce/tinymce-react";

const CreatePost = () => {
  const [postData, setPostData] = useState({
    title: "",
    author: "",
    abstract: "",
    tags: "",
    fulltext: "", // Initialize as empty for the TinyMCE content
  });

  const [pubdate] = useState(new Date().toISOString().split("T")[0]);
  const [posttime] = useState(new Date().toLocaleTimeString());
  const [postuser] = useState("AdminUser"); // Change this to the logged-in user

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPostData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (content) => {
    setPostData((prevData) => ({
      ...prevData,
      fulltext: content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Post Data:", postData);

    try {
      const response = await Axios.post(
        "https://bontech2.herokuapp.com/addpost",
        {
          title: postData.title,
          author: postData.author,
          fulltext: postData.fulltext,
          abstract: postData.abstract,
          tags: postData.tags,
          pubdate,
          posttime,
          postuser,
        }
      );

      console.log("Post created successfully:", response.data);
    } catch (error) {
      console.error(
        "Error creating post:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <div className="create-post-page">
      <h1>Create New Post</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={postData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Author:</label>
          <input
            type="text"
            name="author"
            value={postData.author}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Abstract:</label>
          <textarea
            name="abstract"
            value={postData.abstract}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Tags:</label>
          <input
            type="text"
            name="tags"
            value={postData.tags}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Content:</label>
          <Editor
            apiKey="8w2ak9z27hniwwj1n8umhezymw6ambr1uqouaj2ug5kdtbys" // Replace with your API key
            value={postData.fulltext}
            onEditorChange={handleEditorChange}
            init={{
              height: 500,
              menubar: true, // Enable the menubar
              plugins: [
                "advlist autolink lists link image charmap preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic forecolor backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist outdent indent | removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              setup: (editor) => {
                console.log(editor.settings); // Log the editor settings
              },
            }}
          />
        </div>

        <button type="submit">Create Post</button>
      </form>
    </div>
  );
};

export default CreatePost;
