import React from "react";
import SubpageTemplate from "../components/SubpageTemplate";

const TechContracts = () => {
  const content = `
        My career spans over 20 years of advising on complex technology
        contracts, including software licensing, SaaS agreements, and global
        outsourcing deals. I have extensive experience working with leading tech
        and telecom companies, such as Verizon, Proofpoint, and others. I help
        clients navigate the intricate legal landscape of technology agreements
        by negotiating terms that align with their business objectives, mitigate
        risks, and ensure compliance with evolving regulations in the tech
        sector.`;
  return <SubpageTemplate title="Technology Contracts" content={content} />;
};

export default TechContracts;
