import React, { useEffect, useState } from "react";
import netlifyIdentity from "netlify-identity-widget";

const AuthButtons = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Initialize the Netlify Identity widget
    netlifyIdentity.init();

    // Listen for user login/logout and set the user state accordingly
    netlifyIdentity.on("login", (user) => {
      setUser(user);
      netlifyIdentity.close(); // Close the widget after successful login
    });

    netlifyIdentity.on("logout", () => {
      setUser(null);
    });

    // Cleanup the event listeners when the component unmounts
    return () => {
      netlifyIdentity.off("login");
      netlifyIdentity.off("logout");
    };
  }, []);

  const handleLogin = () => {
    netlifyIdentity.open(); // Open the login widget
  };

  const handleLogout = () => {
    netlifyIdentity.logout(); // Log the user out
  };

  return (
    <div>
      {user ? (
        <button onClick={handleLogout}>Logout</button>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )}
    </div>
  );
};

export default AuthButtons;
