import './App.css';
import React from 'react';
import './index.css';
import "./style.css"
import ShowPrivacy from './components/showprivacy';
import Handlecookies from './components/handlecookies';

function Privacy () {
    return(  
   <div>
    <ShowPrivacy />
    <Footerbon />
    <Handlecookies />
  </div>
   
    )
 }
  
  function Footerbon() {
    return(
      <div className='footer--main'>
      <p>{" "}</p>
      <h6>©2024 PyPanther LLC.</h6>
      </div>
    )
  
  }
  
  
  //need to add the prop function here with the recent work data

export default Privacy;
  
  