import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./BlogPage.css";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await Axios.get(
          "https://bontech2.herokuapp.com/readblogs"
        );
        setPosts(response.data);
        setLoading(false);

        // Log the posts to ensure data integrity
        console.log("Fetched posts:", response.data);
      } catch (error) {
        setError("Error fetching blog posts.");
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Blog - Technomika</title>
        <meta
          name="description"
          content="Stay up to date with the latest insights on data protection, cybersecurity, and technology law on our Technomika blog."
        />
        <meta
          name="keywords"
          content="data protection, cybersecurity, tech law, AI governance, GDPR compliance, legal tech"
        />
        <link rel="canonical" href="https://www.technomika.com/blog" />
        <script src="https://static.elfsight.com/platform/platform.js" defer />
      </Helmet>

      <div className="blog-page-container">
        <div className="blog-posts-container">
          <h1>Blog</h1>

          <div className="posts-grid">
            {posts.map((val) => (
              <div
                key={val.post_id} // Ensure each key is unique
                className="post-card"
                style={{
                  backgroundImage: `url(${
                    val.post_image || "/path-to-placeholder-image.jpg"
                  })`,
                }}
              >
                <div className="post-content">
                  <h2>{val.post_title}</h2>
                  <div className="post-meta">
                    <p>
                      By {val.post_author} |{" "}
                      {new Date(val.post_pubdate).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="post-excerpt">
                    {val.post_abstract
                      ? val.post_abstract.substring(0, 150) + "..."
                      : "No content available"}
                  </div>
                  <Link to={`/blog/${val.post_id}`} className="read-more-link">
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Social Media Feed */}
        <aside className="social-media-feed">
          <h2>Social Media</h2>
          <div className="social-media-content">
            <script
              src="https://static.elfsight.com/platform/platform.js"
              data-use-service-core
              defer
            ></script>
            <div
              className="elfsight-app-02ebe45b-78fb-49c9-98c8-f172cd18ef80"
              data-elfsight-app-lazy
            ></div>
          </div>
        </aside>
      </div>
    </>
  );
};

export default BlogPage;
