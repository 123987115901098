import React from "react";
import { Link } from "react-router-dom";
import "./AdminPage.css"; // Add any necessary CSS for styling the page

const AdminPage = () => {
  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div className="admin-menu">
        <ul>
          <li>
            <Link to="/users/admin/create-post">Create New Post</Link>
          </li>
          <li>
            <Link to="/users/admin/edit-posts">Edit Posts</Link>
          </li>
          <li>
            <Link to="/users/admin/manage-users">Manage Users</Link>
          </li>
          <li>
            <Link to="/users/admin/site-settings">Site Settings</Link>
          </li>
          <li>
            <a
              href="https://nomikacloud-a48b5e9b2189.herokuapp.com/timesheet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Timesheet
            </a>
          </li>
          {/* Add more menu items as needed */}
        </ul>
      </div>
    </div>
  );
};

export default AdminPage;
