import React from "react";
import SimplePageTemplate from "../components/SimplePageTemplate"; // Ensure correct path to the component
import "./About.css"; // Reuse the previous styles

const About = () => {
  return (
    <SimplePageTemplate title="About Us">
      <section className="about-section">
        <h2>Welcome to Technomika</h2>
        <div className="about-intro">
          <div className="profile-picture">
            <img src="/images/profile.jpeg" alt="Rodolfo Arrate" />
          </div>
          <p>
            I am Rodolfo (Ralph) Arrate, the founder of Technomika, a law firm
            specialising in Data Protection, Cybersecurity, Intellectual
            Property, AI Governance, and Technology Contracts. With over two
            decades of experience working both in-house for international
            organisations and independently, I am dedicated to offering clients
            a bespoke, tech-savvy approach to complex legal issues.
          </p>
        </div>
      </section>
      <section className="about-section">
        <h2>Background</h2>
        <p>
          I have a wealth of experience in the technology and legal sectors,
          having worked with leading companies across the UK, Europe, and
          globally. My practice focuses on providing sound legal advice on a
          range of matters including data protection compliance, cybersecurity
          strategy, intellectual property rights, and high-value commercial
          agreements, always mixing in-house pragmatism with sound legal
          training.
        </p>
        <p>
          I am qualified to practice law in England & Wales, France and Spain,
          and am a member of the Bar of Brussels. I have previously worked at
          Verizon, the Hutchison Whampoa Group and Freshfields Bruckhaus
          Deringer. Throughout my career, I have developed a keen understanding
          of the interplay between law, technology, and business, which allows
          me to offer practical, informed legal guidance tailored to my clients'
          needs.
        </p>
      </section>
      <section className="about-section">
        <h2>My Approach</h2>
        <p>
          At Technomika, I offer a personalised service, combining deep legal
          knowledge with an in-house perspective of the technology industry. My
          approach is proactive, meticulous, and client-focused. I take great
          pride in building strong relationships with my clients, ensuring they
          receive the dedicated attention they require. Whether advising on GDPR
          compliance or securing intellectual property rights, I am committed to
          helping clients navigate the complexities of today’s legal landscape
          with confidence.
        </p>
      </section>
      <section className="about-section">
        <h2>Let’s Connect</h2>
        <p>
          If you are seeking an experienced lawyer who understands the nuances
          of technology law and can provide tailored legal advice, I would be
          pleased to hear from you. Please feel free to contact me directly to
          discuss how I can assist with your legal matters.
        </p>
      </section>
    </SimplePageTemplate>
  );
};

export default About;
