import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useLocation } from "react-router-dom";

const ShowPrivacy = () => {
  const pageid = 3;
  const [singlepage, setPage] = useState(null); // Initialize as null
  const blogurl = useLocation();
  console.log(blogurl);

  useEffect(() => {
    Axios.get(`https://bontech2.herokuapp.com/readpage/${pageid}`)
      .then((response) => {
        setPage(response.data[0]); // Assuming response.data is an array with one object
      })
      .catch((error) => {
        console.error("Error fetching the page data:", error);
      });
  }, [pageid]);

  // Ensure the content is only rendered when singlepage has been populated
  if (!singlepage) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog--single--body">
      <div className="foto--row">{/* Image placeholder if necessary */}</div>
      <div className="cookie--page">
        <div className="body--text--item1">
          <p>Areas of Practice</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
        </div>

        <div className="body--text--item2">
          <article className="privacy--page">
            <h1>{singlepage.pages_title}</h1> {/* Use the title from API */}
            <div
              dangerouslySetInnerHTML={{ __html: singlepage.pages_text }}
            />{" "}
            {/* This will correctly render the HTML content */}
          </article>
        </div>

        <div className="cookie--table">
          <h2>Cookies Used</h2>
          <table>
            <thead>
              <tr>
                <th>Cookie Name</th>
                <th>Category</th>
                <th>Purpose</th>
                <th>Duration</th>
                <th>Third Party</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_gid</td>
                <td>Performance</td>
                <td>Analytics</td>
                <td>Daily</td>
                <td>Google Inc.</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>Performance</td>
                <td>Analytics</td>
                <td>Daily</td>
                <td>Google Inc.</td>
              </tr>
              <tr>
                <td>_ga_gtag_UAX...</td>
                <td>Performance</td>
                <td>Analytics</td>
                <td>Daily</td>
                <td>Google Inc.</td>
              </tr>
              <tr>
                <td>_dd_s...</td>
                <td>Strictly Necessary</td>
                <td>Security/Logging</td>
                <td>Daily</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>CookieConsent</td>
                <td>Strictly Necessary</td>
                <td>Security/Logging</td>
                <td>30 days</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowPrivacy;
