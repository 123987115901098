import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import WorkDb from "./workdb";
import Skeleton from "react-loading-skeleton";
import "./showpage.css";
import {
  FaLock,
  FaShieldAlt,
  FaLaptopCode,
  FaFileContract,
  FaLightbulb,
} from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import ElfsightWidget from "./ElfsightWidget";

const ShowPage = () => {
  const pageid = 2;
  const [singlepage, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      setLoading(true);
      try {
        const response = await Axios.get(
          `https://bontech2.herokuapp.com/readpage/${pageid}`
        );
        setPage(response.data);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [pageid]);

  if (loading) {
    return (
      <div className="showpage-container">
        <Skeleton height={30} width={200} count={1} />
        <Skeleton height={20} width={`80%`} count={5} />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Multilingual Tech Law Firm - TECHNOMIKA</title>
        {/* Elfsight Widget */}
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <meta
          name="description"
          content="Delivering high-quality legal services in data privacy, tech contracts, and IP across jurisdictions."
        />
        <meta
          name="keywords"
          content="Data Privacy, Cybersecurity, Tech Contracts, IP Law, International Law"
        />
        <link rel="canonical" href="https://www.technomika.com/" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "TECHNOMIKA",
        "url": "https://www.technomika.com",
        "logo": "https://www.technomika.com/nomikalogo.png",
        "sameAs": [
          "https://www.linkedin.com/company/technomika",
          "https://twitter.com/technomika"
        ]
      }
    `}
        </script>
      </Helmet>

      <header aria-label="Page header">
        <HeroSection />
      </header>
      <main>
        <section className="main-content-box" aria-label="Main Content">
          <script
            src="https://static.elfsight.com/platform/platform.js"
            data-use-service-core
            defer
          ></script>
          <div
            class="elfsight-app-1b459ff1-a315-4e26-a8e5-1524059a838b"
            data-elfsight-app-lazy
          ></div>
        </section>
        <section className="main-content-box" aria-label="Main Content">
          <MainContent singlepage={singlepage} />
        </section>
        <section aria-label="Areas of Practice">
          <AreasOfPractice />
        </section>
        <section aria-label="Recent Work" className="recent-work-container">
          <div className="recent-work-section">
            <h2>Recent Work</h2>
            <WorkDb />
            <button className="cta-button">
              <Link
                to="/contact"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Learn More
              </Link>
            </button>
          </div>

          <aside className="right-column">
            <h3>LinkedIn Feed</h3>
            <div>
              {/* Add the Elfsight LinkedIn Widget */}
              <script
                src="https://static.elfsight.com/platform/platform.js"
                data-use-service-core
                defer
              ></script>
              <div
                className="elfsight-app-02ebe45b-78fb-49c9-98c8-f172cd18ef80"
                data-elfsight-app-lazy
              ></div>
            </div>
          </aside>
        </section>
      </main>

      <footer aria-label="Footer">{/* Footer content */}</footer>
    </>
  );
};

const HeroSection = () => (
  <div className="hero-section">
    <div className="hero-content">
      <h1>Multilingual, Tech-savvy, In-house seasoned</h1>
      <p>
        Delivering high-quality legal services in data privacy, tech contracts,
        and IP across jurisdictions.
      </p>
      {/* Use Link as a button */}
      <Link to="/contact" className="cta-button">
        Get in Touch
      </Link>
    </div>
  </div>
);

const AreasOfPractice = () => (
  <div className="areas-of-practice">
    <h3>Areas of Practice</h3>
    <div className="practice-cards">
      <PracticeCard
        icon={<FaLock />}
        title="Data/Privacy"
        link="/services/data-privacy"
        excerpt="Expert legal counsel on data protection and privacy compliance."
      />
      <PracticeCard
        icon={<FaShieldAlt />}
        title="Cybersecurity"
        link="/services/cybersecurity"
        excerpt="Advising on cyber threats and security strategies."
      />
      <PracticeCard
        icon={<FaLaptopCode />}
        title="AI Governance"
        link="/services/ai-governance"
        excerpt="Specialized legal services for AI governance and ethics."
      />
      <PracticeCard
        icon={<FaFileContract />}
        title="Tech Contracts"
        link="/services/tech-contracts"
        excerpt="Drafting and negotiating complex technology agreements."
      />
      <PracticeCard
        icon={<FaLightbulb />}
        title="IP / Confidentiality"
        link="/services/ip-confidentiality"
        excerpt="Protecting intellectual property and confidentiality."
      />
    </div>
  </div>
);

const PracticeCard = ({ icon, title, link, excerpt }) => (
  <div className="practice-card">
    <Link to={link} aria-label={title}>
      <div className="card-icon" role="img" aria-label={`${title} icon`}>
        {icon}
      </div>
      <h4>{title}</h4>
      <p>{excerpt}</p>
    </Link>
  </div>
);

const MainContent = ({ singlepage }) => (
  <div className="main-content">
    {singlepage.map((val, key) => (
      <article className="single-page-content" key={key}>
        <h2>{val.pages_title}</h2>
        <p>{val.pages_text}</p>
      </article>
    ))}
  </div>
);

const BlogPostImage = () => (
  <LazyLoadImage
    src="/path-to-image.jpg"
    alt="Blog Post Image"
    effect="blur"
    placeholderSrc="/path-to-placeholder.jpg"
    width="100%"
    height="auto"
  />
);

export default ShowPage;
