import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";

export default function HandleCookies() {
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);

  useEffect(() => {
    const isAnalyticsAccepted = Cookies.get("analyticsCookies");
    if (isAnalyticsAccepted === "true") {
      loadGoogleAnalytics();
      loadGoogleTagManager();
    }
  }, []);

  const loadGoogleAnalytics = () => {
    if (!window.gtag && !document.getElementById("ga-script")) {
      const script = document.createElement("script");
      script.id = "ga-script";
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-BH755Y8JKT`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag("js", new Date());
        window.gtag("config", "G-BH755Y8JKT", { anonymize_ip: true });
      };
    }
  };

  const loadGoogleTagManager = () => {
    if (!document.getElementById("gtm-script")) {
      const script = document.createElement("script");
      script.id = "gtm-script";
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-XXXXXXX";
      script.async = true;
      document.head.appendChild(script);
    }
  };

  const removeGoogleAnalyticsCookies = () => {
    const gaCookies = ["_ga", "_gid", "_gat"];
    gaCookies.forEach((cookie) =>
      Cookies.remove(cookie, { path: "/", domain: window.location.hostname })
    );
  };

  const handleAcceptEssential = () => {
    removeGoogleAnalyticsCookies();
    Cookies.set("essentialCookies", "true", { expires: 365 });
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setShowPreferencesModal(false);
  };

  const handleAcceptAnalytics = () => {
    Cookies.set("analyticsCookies", "true", { expires: 365 });
    Cookies.set("cookieConsent", "true", { expires: 365 });
    loadGoogleAnalytics();
    loadGoogleTagManager();
    setShowPreferencesModal(false);
  };

  const handleAcceptAll = () => {
    Cookies.set("allCookies", "true", { expires: 365 });
    Cookies.set("essentialCookies", "true", { expires: 365 });
    Cookies.set("analyticsCookies", "true", { expires: 365 });
    Cookies.set("cookieConsent", "true", { expires: 365 });
    loadGoogleAnalytics();
    loadGoogleTagManager();
    setShowPreferencesModal(false);
  };

  const handleDecline = () => {
    removeGoogleAnalyticsCookies();
    Cookies.set("cookieConsent", "declined", { expires: 365 });
    setShowPreferencesModal(false);
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        declineButtonText="Decline"
        enableDeclineButton
        onAccept={handleAcceptAll}
        onDecline={handleDecline}
        style={{
          background: "#333",
          color: "#fff",
          fontSize: "1rem",
          padding: "16px",
          borderTop: "4px solid #f7b731",
        }}
        buttonStyle={{
          backgroundColor: "#f7b731",
          color: "#000",
          fontSize: "1rem",
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          marginLeft: "10px",
          transition: "background-color 0.3s ease",
        }}
        declineButtonStyle={{
          backgroundColor: "#777",
          color: "#fff",
          fontSize: "1rem",
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          transition: "background-color 0.3s ease",
        }}
      >
        We use cookies to improve your experience. Please select your
        preferences.
      </CookieConsent>

      <div
        style={{
          position: "fixed",
          bottom: "20px", // Adjust to avoid overlap
          right: "20px",
          zIndex: 1000,
        }}
      >
        <button
          onClick={() => setShowPreferencesModal(true)}
          style={{
            background: "#4e503b",
            color: "#FFF",
            border: "none",
            padding: "12px 24px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "1rem",
            transition: "background-color 0.3s ease",
          }}
        >
          Modify Cookie Preferences
        </button>
      </div>

      {showPreferencesModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1050,
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
              maxWidth: "500px",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                color: "#333",
                marginBottom: "20px",
              }}
            >
              Cookie Preferences
            </h2>
            <button
              onClick={handleAcceptEssential}
              style={{
                backgroundColor: "#4e503b",
                color: "#fff",
                fontSize: "1rem",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                border: "none",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              Accept Essential Cookies
            </button>
            <button
              onClick={handleAcceptAnalytics}
              style={{
                backgroundColor: "#f7b731",
                color: "#000",
                fontSize: "1rem",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                border: "none",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              Accept Analytics Cookies
            </button>
            <button
              onClick={() => setShowPreferencesModal(false)}
              style={{
                backgroundColor: "#777",
                color: "#fff",
                fontSize: "1rem",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                border: "none",
                width: "100%",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
